import { css } from "@emotion/core"

import destinLogo from "../../images/destin-logo.png"
import tugoLogo from "../../images/tugo-logo.png"
import gmsLogo from "../../images/gms-logo.png"

import { mq } from "./media-query"

const imgStyle = {
  width: "100%",
  height: "200px",
  margin: "0 auto 1rem auto",
  textAlign: "cover",
  "& img": {
    objectFit: "contain",
    // minHeight: "90%",
    width: "50%",
    height: "80%",

    [mq[2]]: {
      maxWidth: "80%",
    },
  },
}
const extraImgStyle = {
  paddingTop: "1.5rem",
}

const captionStyle = {
  textTransform: "capitalize",
  fontWeight: "700",
  color: "var(--clr-primary-300)",
  fontSize: "1.25rem",
  letterSpacing: "var(--letter-spacing-sm)",
  width: "60%",
  marginLeft: "auto",
  marginRight: "auto",
}

const cardStyle = css`
  background: none;
  border-radius: var(--border-radius-md);
  text-align: center;
  box-shadow: var(--dark-shadow-sb);
  margin: 0 auto 2rem;
  width: 90%;
  padding: 1rem;
  align-self: flex-start;

  ${mq[1]} {
    margin: 0 auto;
  }
`

const hoverEffect = css`
  &:hover {
    box-shadow: none;
    border: 2px solid var(--clr-primary-300);
    cursor: pointer;
  }
`

export default [
  {
    image: {
      src: destinLogo,
      alt: "destination-logo",
    },
    label: "destination",
    caption: "destination",
    capId: "insurer.destin",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    hoverEffect,
    captionStyle,
  },
  {
    image: {
      src: gmsLogo,
      alt: "GMS-logo",
    },
    label: "gms",
    caption: "GMS",
    capId: "insurer.gms",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    hoverEffect,
  },
  {
    image: {
      src: tugoLogo,
      alt: "Tugo-logo",
    },
    label: "tugo",
    caption: "Tugo",
    capId: "insurer.tugo",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    hoverEffect,
  },
]
